exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js?modules&importLoaders=1&localIdentName=[path]___[name]__[local]___[hash:base64:5]!../../styles/variables.css"), "");

// module
exports.push([module.id, ".src-web-components-sidebar-___sidebar__sidebar___3xRV9 {\n  height: 100%;\n  background-color: var(--color--primary-500);\n  width: 260px;\n  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),\n    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);\n}\n\n.src-web-components-sidebar-___sidebar__sidebar___3xRV9 .sidebar-text {\n  color: var(--color--neutral-050);\n}\n.src-web-components-sidebar-___sidebar__sidebar___3xRV9 .sidebar-icon {\n  color: rgba(255, 255, 255, 0.54);\n}\n", ""]);

// exports
exports.locals = {
	"sidebar": "src-web-components-sidebar-___sidebar__sidebar___3xRV9"
};