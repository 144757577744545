exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js?modules&importLoaders=1&localIdentName=[path]___[name]__[local]___[hash:base64:5]!../../styles/variables.css"), "");

// module
exports.push([module.id, "body {\n  font-family: var(--font--primary);\n}\n\nh3 {\n  color: var(--color--primary-500);\n}\n\n.src-web-containers-main-___main__outer___2V_zE {\n  padding-bottom: 4rem;\n}\n\n.src-web-containers-main-___main__main___2KJs6 {\n}\n\n.src-web-containers-main-___main__main-route___1A9Jv {\n}\n\n.src-web-containers-main-___main__main-route___1A9Jv h2 {\n  margin-top: 0;\n}\nul {\n  padding: 0;\n}\nul li {\n  list-style-position: inside;\n}\nhr {\n  height: 1px;\n  background-color: var(--color--neutral-100);\n  border: 0;\n}\n\na {\n  text-decoration: none;\n  color: var(--color--primary-500);\n}\na:visited {\n  color: var(--color--primary-500);\n}\n.hidden {\n  display: none;\n}\n.paper {\n  padding: 1rem;\n}\n.spaced {\n  margin: 1rem 0;\n}\n.nowrap {\n  white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"outer": "src-web-containers-main-___main__outer___2V_zE",
	"main": "src-web-containers-main-___main__main___2KJs6",
	"main-route": "src-web-containers-main-___main__main-route___1A9Jv"
};