import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './private-route';

const Search = React.lazy(() => import('containers/search'));
const ProcedureList = React.lazy(() => import('containers/procedure-list'));
const ProcedureAdd = React.lazy(() => import('containers/procedure-add'));
const ProcedureExport = React.lazy(() => import('containers/procedure-export'));
const ProcedureBase = React.lazy(() => import('containers/procedure-base'));
const LotAdd = React.lazy(() => import('containers/lot-add'));
const LotBase = React.lazy(() => import('containers/lot-base'));
const Login = React.lazy(() => import('containers/login'));
const Account = React.lazy(() => import('containers/account'));
const UserList = React.lazy(() => import('containers/user-list'));
const UserAdd = React.lazy(() => import('containers/user-add'));
const UserEdit = React.lazy(() => import('containers/user-edit'));
const UserBrokerList = React.lazy(() => import('containers/user-broker-list'));
const UserBrokerAdd = React.lazy(() => import('containers/user-broker-add'));
const UserBrokerEdit = React.lazy(() => import('containers/user-broker-edit'));
const RelatedLists = React.lazy(() => import('containers/related-lists'));
const FlowList = React.lazy(() => import('containers/flow-list'));
const Week = React.lazy(() => import('containers/week'));
const WeekTotal = React.lazy(() => import('containers/week-total'));
const Alerts = React.lazy(() => import('containers/alerts'));
const Activities = React.lazy(() => import('containers/activities'));

export default ({ user }) => (
  <div>
    <Suspense fallback={<div>Loading...</div>}>
      <Route path="/login" component={Login} />
      <PrivateRoute user={user} exact path="/" component={Search} />
      <PrivateRoute
        user={user}
        exact
        path="/procedures"
        component={ProcedureList}
      />
      <PrivateRoute
        user={user}
        exact
        path="/procedure-add"
        component={ProcedureAdd}
      />
      <PrivateRoute
        user={user}
        path="/procedure/:id"
        component={ProcedureBase}
      />
      <PrivateRoute
        exact
        user={user}
        path="/procedure-export"
        component={ProcedureExport}
      />
      <PrivateRoute
        exact
        user={user}
        path="/procedure-export/:userId"
        component={ProcedureExport}
      />
      <PrivateRoute
        user={user}
        path="/lot-add/:procedureId/:procedureCustomId"
        component={LotAdd}
      />
      <PrivateRoute user={user} path="/lot/:id" component={LotBase} />
      <PrivateRoute user={user} path="/account" component={Account} />
      <PrivateRoute user={user} path="/users" component={UserList} />
      <PrivateRoute user={user} path="/user-add" component={UserAdd} />
      <PrivateRoute user={user} path="/user-edit/:id" component={UserEdit} />
      <PrivateRoute
        user={user}
        path="/user-brokers"
        component={UserBrokerList}
      />
      <PrivateRoute
        user={user}
        path="/user-broker-add"
        component={UserBrokerAdd}
      />
      <PrivateRoute
        user={user}
        path="/user-broker-edit/:id"
        component={UserBrokerEdit}
      />
      <PrivateRoute user={user} path="/related" component={RelatedLists} />
      <PrivateRoute
        user={user}
        path="/flows/:procedureId/:procedureCustomId"
        component={FlowList}
      />
      <PrivateRoute user={user} path="/week/:id" component={Week} />
      <PrivateRoute user={user} path="/week-total" component={WeekTotal} />
      <PrivateRoute user={user} path="/alerts" component={Alerts} />
      <PrivateRoute user={user} path="/activities" component={Activities} />
      <PrivateRoute
        user={user}
        path="/user/:user/activities"
        component={Activities}
      />
    </Suspense>
  </div>
);
