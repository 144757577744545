export const MESSAGE_NEW = 'MESSAGE_NEW';
export const MESSAGE_READ = 'MESSAGE_READ';

export const newMessage = message => ({
  type: MESSAGE_NEW,
  message,
});

export const readMessage = () => ({
  type: MESSAGE_READ,
});
