import logoNCM from 'assets/img/logo.png';
import loginBackgroundNCM from 'assets/img/bs.jpg';
import blue from '@material-ui/core/colors/blue';

const brand = {
  title: 'NCM',
  loginBackground: loginBackgroundNCM,
  logo: logoNCM,
  color: blue,
};

export default brand;
