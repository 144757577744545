import React from 'react';
import { connect } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListAltIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import WorkIcon from '@material-ui/icons/Work';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/AccountBox';
import WeekIcon from '@material-ui/icons/CalendarToday';
import TodayIcon from '@material-ui/icons/Today';
import AlertsIcon from '@material-ui/icons/Send';
import ActivitiesIcon from '@material-ui/icons/Computer';

import { closeSidebar, openSidebar } from 'actions/sidebar';
import { isSuper, admin } from 'utils/role';
import brand from 'config/brand';

import Item from './item';

import './sidebar.css';

const Sidebar = ({ user, open, closeSidebar }) => (
  <SwipeableDrawer open={open} onClose={closeSidebar} onOpen={openSidebar}>
    <div styleName="sidebar" role="button" onClick={closeSidebar}>
      <List>
        {brand.logo && (
          <React.Fragment>
            <ListItem>
              <img
                style={{ width: '100%' }}
                src={brand.logo}
                alt={brand.title}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        )}
        <Item Icon={SearchIcon} text="Ricerca" to="/" />
        <Item Icon={AddIcon} text="Nuova pratica" to="/procedure-add" />
        <Item Icon={ListAltIcon} text="Elenco pratiche" to="/procedures" />
        <Item Icon={WorkIcon} text="Brokers" to="/user-brokers" />
        {isSuper(user) && <Item Icon={PeopleIcon} text="Utenti" to="/users" />}
        {isSuper(user) && (
          <Item Icon={SettingsIcon} text="Gestione" to="/related" />
        )}
        {isSuper(user) && <Item Icon={AlertsIcon} text="Avvisi" to="/alerts" />}
        {admin(user) && (
          <Item Icon={ActivitiesIcon} text="Attività" to="/activities" />
        )}
        <Divider />
        <Item Icon={WeekIcon} text="Lavorazione totale" to="/week-total" />
        <Item
          Icon={TodayIcon}
          text="Lavorazione commerciale"
          to={user && `/week/${user._id}`}
        />
        <Item Icon={UserIcon} text={user && user.username} to="/account" />
      </List>
    </div>
  </SwipeableDrawer>
);

const mapStateToProps = state => ({
  open: state.sidebar.open,
  user: state.user,
});

const mapDispatchToProps = { closeSidebar, openSidebar };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
