import React from 'react';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { ApolloProvider } from 'react-apollo';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';
import itLocale from 'date-fns/locale/it';

import store from 'store';
import Main from 'containers/main';
import theme from 'styles/theme';
import { client } from '../apollo';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <React.Fragment>
            <CssBaseline />
            <Main />
          </React.Fragment>
        </Provider>
      </ApolloProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

export default hot(App);
